.cart{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.left{
    flex: 1 1 calc(70% - 1rem); 
    max-width: calc(70% - 1rem);
    border-radius: 1rem;
    height: 80vh; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
  

}

.right{
    flex: 1 1 calc(30% - 1rem); 
    max-width: calc(30% - 1rem);
    border-radius: 1rem;
    height: 80vh; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */


}

.right button{
    width: 100%;
    padding: 1rem;
    border-radius: 1.5rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    background-color: #000000;
    color: #ffffff;
    font-size: 1rem;

}



.left-card{
    display: flex;
    flex-direction: column;
   gap: 2rem;
   
}

.left-card-part1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
   
}


.left-card-1{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.left-card-1 img{
    flex: 1 1 calc(50% - 1rem); 
    max-width: calc(50% - 1rem);
    height: 30vh;
}





@media  (max-width: 768px) {
    .cart{
        flex-direction: column; 
    }

    .left{
        flex: 1 1 100%; 
        max-width: 100%;
      
    }
    .right{
        flex: 1 1 100%; 
        max-width: 100%;
       
    }

    .left-card-1 img{
        flex: 1 1 100%; 
        max-width: 100%;
        height: 50vh;
    }

    .left-card-1{
        flex-direction: column; 
    }
    .left-card-part1{
        flex-direction: column; 
    }
    .left-card-2{
        padding-top: 2rem;
    }



}


.sec-header {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    font-size: 2rem;
}

.sec-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

.sec-card {
    flex: 1 1 calc(25% - 1rem); /* Adjust width to cover spaces automatically */
    max-width: calc(25% - 1rem); /* Ensure cards don’t exceed the row width */
    height: 55vh;
    border-radius: 1rem;
    box-sizing: border-box;
}

.sec-card img {
    width: 100%;
    height: 43vh;
    border-radius: 1rem;
}

.section {
    padding-top: 1rem;
    padding-left: 2rem; /* Updated padding */
    padding-right: 2rem; /* Updated padding */
    padding-bottom: 4rem;
}



@media (max-width: 768px) {
    .sec-card {
        flex: 1 1 calc(50% - 1rem); /* 2 cards per row for small screens */
        max-width: calc(50% - 1rem);
    }
}

@media (max-width: 480px) {
    .sec-card {
        flex: 1 1 100%; /* 1 card per row for extra small screens */
        max-width: 100%;
    }
}


  




