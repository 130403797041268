.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Ensure the modal doesn't overflow outside the viewport */
}

.modal-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 600px;
  max-width: 100%;
  height: 80dvh; /* Fixed height relative to the viewport */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
}

.close-button, .confirm-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #f44336; /* Red color for close */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-button {
  background: green; /* Green for confirmation button */
}
