@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');


@font-face {
    font-family: 'Hydot';
    src: url('../public/font/Intrepid Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hydot-Bold';
    src: url('../public/font/Intrepid.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Hydot', Helvetica, Arial, sans-serif;
}
  


@tailwind base;
@tailwind components;
@tailwind utilities;